import '../styles/app.scss';
import $ from 'jquery';

import 'bootstrap/dist/js/bootstrap.js';

if (window.matchMedia('(min-width: 992px)').matches) {
    $('.headerNavListItem.parent').on('mouseover', function() {
        $(this).find('.headerNavListItemLink').addClass('hover');
        $(this).find('.headerNavListSub').show();
    }).on('mouseleave', function(e) {
        if (!$(e.toElement).hasClass('headerNavListSub')) {
            $(this).parent().find('.headerNavListItemLink').removeClass('hover');
            $(this).parent().find('.headerNavListSub').hide();
        }
    });
} else {
    $('.headerNavListItem').on('click', function() {
        $(this).find('.headerNavListItemLink').toggleClass('hover');
        $(this).find('.headerNavListSub').toggle();
    });
}

$('.headerNavMobile').on('click', function() {
    $(this).toggleClass('opened');
    $('.headerNav').slideToggle();
    $('body').toggleClass('no');
    $('.searchContainer').hide();
    $('.searchOpen').removeClass('hover');
});

$('.searchOpen').on('click', function() {
    $('.searchContainer').toggle().css('top', $('.header').offset().top + $('.header').height() +'px' );
    $('body').toggleClass('no');
    $(this).toggleClass('hover');
    if (window.matchMedia('(max-width: 991px)').matches) {
        $('.headerNavMobile').removeClass('opened');
        $('.headerNav').hide();
    }
});
$('.searchClose').on('click', function() {
    $('.searchContainer').hide();
    $('body').removeClass('no');
    $('.searchOpen').removeClass('hover');
});

$(window).resize(function() {
    if (window.matchMedia('(min-width: 992px)').matches) {
        $('.headerNavMobile').removeClass('opened');
        $('body').removeClass('no');
        $('.headerNav').show();
    } else {
        $('.headerNav').hide();
    }
});
